import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import Footer from './Navigation/Footer';
import discordImage from '../assets/main/discord.png';
import { currentLogo, currentBrandingColors, currentSubdomain } from './whitelabel/constants';

const GreenTick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 text-green-600 inline-block mr-2">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
  </svg>
);

const RedCrossWithCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 text-red-600 inline-block mr-2">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 6l12 12M6 18L18 6" />
    <circle cx="12" cy="12" r="9" />
  </svg>
);

const ReviewCard = ({ reviewerName, reviewerImage, reviewText }) => (
  <div className="review-card bg-slate-900 p-4 rounded-lg shadow-lg text-left space-y-4 w-full md:w-1/3 flex flex-col justify-evenly">
    <h4 className="text-xl font-semibold text-gray-100 text-center">{reviewerName}</h4>
    <p className="text-gray-300 text-center">{reviewText}</p>
  </div>
);

const GetFitterAILanding = () => {
  const navigate = useNavigate();
  const [gifSize, setGifSize] = useState({ width: '800px', height: '500px' });

  useEffect(() => {
    (async () => {
      const { data } = await supabase.auth.getSession();
      if (data && data.session !== null) {
        navigate('/diet');
      }
    })();
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setGifSize({ width: '100%', height: 'auto' });
      } else if (window.innerWidth < 1200) {
        setGifSize({ width: '1200px', height: 'auto' });
      } else {
        setGifSize({ width: '5500px', height: '3500' });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Removed empty useEffect

  const reviews = [
    {
      reviewerName: "Mike Thompson",
      reviewText: "Love the personalized meal plans! Noticed improvements in just weeks.",
    },
    {
      reviewerName: "Sophia Martinez",
      reviewText: "I was skeptical of digital fitness platforms, but GetFitterAI changed my perspective. The custom diets have transformed my routine, making it effective and easy to follow. Highly recommend!",
    },
    {
      reviewerName: "Liam Wilson",
      reviewText: "With GetFitterAI, I got a tailored workout regime. It's user-friendly and has kept me consistent.",
    },
  ];

  return (
    <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-10 mt-10">
      


      {/* Existing Content */}
      <div className="animate__animated animate__fadeIn text-center space-y-8 w-full max-w-2xl">
        <h1 className="text-[3.5rem] leading-none sm:text-4.5xl tracking-tight font-bold text-gray-100 dark:text-white">
          A personal trainer shouldn't cost 100's of dollars
        </h1>
              {/* New App Download Section */}
          <div className="w-full max-w-4xl flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
            <a href="https://rb.gy/yxwgey" target="_blank" rel="noopener noreferrer">
              <img
                src="https://lh3.googleusercontent.com/-dxFgBBDvKbfFfUQcWqmK4_V2hIz_4bB04tFuEwRpzGp6kHJZdQ75hVd3wReOm3FtBc=w2400"
                alt="Download on the App Store"
                className="w-48 h-auto hover:opacity-80 transition-opacity duration-200"
              />
            </a>
             <a href="https://rb.gy/568zhq" target="_blank" rel="noopener noreferrer">
              <img
                src="https://lh6.googleusercontent.com/MtTYDVbzO57Zl1wdkN3uxlRSBbGxFgmCRvIn0YAcPHNQSeOK6Ck9PhYPit29hqxKFW4=w2400"
                alt="Get it on Google Play"
                className="w-48 h-auto hover:opacity-80 transition-opacity duration-200"
              />
            </a> 
          </div>
        <h2 className="text-2xl font-light text-gray-300">
          Experience personalized diet and workout plans with GetFitterAI
        </h2>
        <button
          onClick={() => navigate('/signup')}
          className="py-4 px-10 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded-full text-xl hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 focus:ring-offset-slate-50 mb-6"
        >
          Start 7 days free trial
        </button>

        <div className="w-full mb-6 flex justify-center">
          <img
            src="https://raw.githubusercontent.com/prudhvireddym/getfitter-logos/main/videos/cropped-getfitter.gif"
            alt="Get Fitter GIF"
            style={{ width: gifSize.width, height: gifSize.height }}
            className="rounded-lg shadow-xl animate__animated animate__fadeInUp w-full h-auto"
          />
        </div>
      </div>

      <div className="w-full max-w-4xl space-y-8">
        <h3 className="text-3xl font-semibold text-gray-100 text-center">Powerful Features</h3>

        <div className="flex flex-wrap justify-around items-start space-y-6 md:space-y-0">
          <FeatureCard
            title="Personal AI Trainer"
            description="AI trainer that can access and update your diet and workout plans according to your requests"
          />
          <FeatureCard
            title="Custom Diets"
            description="Tailored diets to fit your nutritional needs and preferences."
          />
          <FeatureCard
            title="Custom Workouts"
            description="Personalized workout regimes for your unique fitness goals."
          />
          <FeatureCard
            title="Replace Diet & Workout"
            description="Replace any given Diet & Workout that you do not like with a single click"
          />
          <FeatureCard
            title="24/7 AI Trainer"
            description="24/7 Ai trainer that has context about you and your active diet, workout plans"
          />
        </div>
      </div>

      <div className="mt-8 text-center">
        <button
          onClick={() => navigate('/signup')}
          className="py-4 px-10 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded-full text-xl hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 focus:ring-offset-slate-50 mb-6"
        >
          Start 7 days free trial
        </button>
      </div>

      <div className="mt-6 text-center">
        <a href="https://discord.gg/wfbNfd5PFk" target="_blank" rel="noopener noreferrer">
          <img src={discordImage} alt="Join us on Discord" className="w-23 h-16 hover:opacity-80 transition-opacity duration-200" />
        </a>
      </div>

      <div className="w-full max-w-4xl space-y-8 mt-8">
        <h3 className="text-3xl font-semibold text-gray-100 text-center">What Our Users Say</h3>

        <div className="flex flex-wrap justify-center items-start space-y-6 md:space-y-0">
          {reviews.map((review, index) => (
            <ReviewCard key={index} {...review} />
          ))}
        </div>
      </div>

      <div className="mt-4 text-center">
        <span className="text-gray-300 text-lg">Already have an account? </span>
        <span onClick={() => navigate('/signin')} className="text-slate-400 hover:underline cursor-pointer">
          Sign in
        </span>
      </div>

      <div className="w-full max-w-4xl space-y-8">
        <h3 className="text-3xl font-semibold text-gray-100 text-center">Pricing Plans</h3>

        <div className="flex flex-col md:flex-row justify-center items-start space-y-6 md:space-y-0 md:space-x-4">
          <PricingCard
            title="Most Popular Plan"
            price="4.99$/Month"
            features={[
              "Personalized 3 Meals",
              "Personalized workout plan",
              "Unlimited number of meal resets",
              "Unlimited replace Meals",
              "Unlimited workout resets",
              "Unlimited replace Workouts",
              "Priority support",
            ]}
            navigate={navigate}
          />

          <PricingCard
            title="Best Value Plan"
            price="49.99$/Year"
            features={[
              "Personalized 3 Meals",
              "Personalized workout plan",
              "Unlimited number of meal resets",
              "Unlimited replace Meals",
              "Unlimited workout resets",
              "Unlimited replace Workouts",
              "Priority support",
            ]}
            navigate={navigate}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

const FeatureCard = ({ title, description }) => (
  <div className="feature-card w-full md:w-1/3 p-4 space-y-4 animate__animated animate__fadeIn">
    <h4 className="text-xl font-semibold text-gray-100 text-center">{title}</h4>
    <p className="text-gray-300">{description}</p>
  </div>
);

const PricingCard = ({ title, price, features, navigate }) => (
  <div className="pricing-card bg-slate-900 border border-gray-700 rounded-lg shadow-lg overflow-hidden">
    <div className="p-5">
      <h4 className="text-2xl font-bold text-gray-100 mb-4 text-center">{title}</h4>
      <p className="text-lg font-semibold text-center text-green-500">{price}</p>
      <ul className="mt-6 space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-2">
            <GreenTick />
            <span className="text-gray-300">{feature}</span>
          </li>
        ))}
      </ul>
    </div>

    <div className="bg-slate-800 p-5 text-center">
      <button
        onClick={() => navigate('/signup')}
        className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white font-bold rounded transition duration-300 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 focus:ring-offset-slate-50"
      >
        Select
      </button>
    </div>
  </div>
);

export default GetFitterAILanding;
