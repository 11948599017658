import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useSelector, useDispatch } from 'react-redux';
import TiktokPixel from 'tiktok-pixel';


  const MealPlanPreferences = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const [diet, setDiet] = useState('standard');
  const [foodType, setFoodType] = useState('American');
  const [userFavFood, setUserFavFood] = useState('');
  const [selectedProtein, setSelectedProtein] = useState([]); 
  const [user_allergies, setUserAllergies] = useState(''); // Added this for food allergies
  const user_goal_tdee = localStorage.getItem('user_goal_tdee');
  const user_tdee = localStorage.getItem('user_tdee');

  const proteinSources = ['Poultry', 'Farm Meat', 'Seafood', 'Eggs', 'Tofu', 'Beans', 'Lentils', 'Nuts', 'Seeds', 'Dairy'];


  const toggleProtein = (protein, event) => {
    event.preventDefault(); // Prevent form submission
    setSelectedProtein(prevProtein => {
      if (prevProtein.includes(protein)) {
        return prevProtein.filter(d => d !== protein);
      } else {
        return [...prevProtein, protein];
      }
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    // Store the meal preferences in local storage
    localStorage.setItem('user_diet_preference', diet);
    localStorage.setItem('user_food_preference', foodType); 
    localStorage.setItem('user_preferred_protein_sources', JSON.stringify(selectedProtein));
    localStorage.setItem('user_fav_food', userFavFood);
    localStorage.setItem('user_allergies', user_allergies); // Store the food allergies



    const storedTokenData = localStorage.getItem("sb-ygcptpjdpipejtvnjnlg-auth-token");

    // Parsing the JSON token object
    const parsedTokenData = JSON.parse(storedTokenData);
  
    // Extracting the user ID from the parsed token object
    const user_id = parsedTokenData.user.id;

    const jwtToken = parsedTokenData.access_token;

    // Gather all required information from local storage
    const payload = {
      user_allergies: localStorage.getItem('user_allergies'),
      user_age: localStorage.getItem('user_age'),
      user_preferred_protein_sources:localStorage.getItem('user_preferred_protein_sources'),
      user_fav_food:localStorage.getItem('user_fav_food'),
      user_fitness_goal: localStorage.getItem('user_fitness_goal'),
      user_food_preference: localStorage.getItem('user_food_preference'),
      user_gender: localStorage.getItem('user_gender'),
      user_goal_tdee: localStorage.getItem('user_goal_tdee'),
      user_tdee: localStorage.getItem('user_tdee'),
      user_weight_in_kgs: localStorage.getItem('user_weight_in_kgs'),
      user_height_in_cm: localStorage.getItem('user_height_in_cm'),
      user_avoid_food: localStorage.getItem('user_avoid_food'),
      user_activity: localStorage.getItem('user_activity'),
      user_diet_preference: localStorage.getItem('user_diet_preference'),
      user_preferred_workout_days: localStorage.getItem('user_preferred_workout_days'),
      user_workout_type: localStorage.getItem('user_workout_type'),
      user_experience_level: localStorage.getItem('user_experience_level'),
      user_id: user_id
    };

    let provider = parsedTokenData.user.app_metadata.provider;
    if (provider === 'google') {
        console.log("provider is google")
        let user_email = parsedTokenData.user.user_metadata.email;
        let user_full_name = parsedTokenData.user.user_metadata.full_name;
        let user_id = parsedTokenData.user.id;

        // Assuming payload is defined somewhere
        payload['user_email'] = user_email;
        payload['user_full_name'] = user_full_name;

        // Caution with storing sensitive information in local storage
        localStorage.setItem('user_email', user_email);
        localStorage.setItem('user_full_name', user_full_name);
        localStorage.setItem('user_id', user_id);
    }
    else{
      console.log("provider is not google -",provider)
      let user_email = parsedTokenData.user.user_metadata.user_email;
      let user_full_name = parsedTokenData.user.user_metadata.user_full_name;
      let user_id = parsedTokenData.user.id;

        // Assuming payload is defined somewhere
        payload['user_email'] = user_email;
        payload['user_full_name'] = user_full_name;
        payload['write_table'] = 'my_users';

        // Caution with storing sensitive information in local storage
        localStorage.setItem('user_email', user_email);
        localStorage.setItem('user_full_name', user_full_name);
        localStorage.setItem('user_id', user_id);
    }
    

    const handleRegistrationSuccess = () => {
      // Trigger the custom event to TikTok Pixel
      window.ttq.track('CompleteRegistration');
    };

   

        

    const checkAuthentication = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (data && data.session) {
          console.log('User is authenticated:', data);
      } else {
          if (error) {
              console.error('Error checking authentication:', error.message);
          } else {
              console.log('User is not authenticated.');
            }
        }
    };
    checkAuthentication();
    
    // Send the data to localhost:8000
    try {
      const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${parsedTokenData['access_token']}`
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log('Data sent to localhost:8000', result);
    } catch (fetchError) {
      console.error('Error sending data to localhost:8000', fetchError);
    }

    handleRegistrationSuccess();

    // Redirect or handle the preferences as needed
    navigate('/diet'); // Navigate to the next page if required
  };
  
  return (
      <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
          <h1 className="text-xl mt-2 leading-none sm:text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
              Tell us your meal plan preferences!
          </h1>
  
          {user_tdee && user_goal_tdee && (
              <p className="text-sm sm:text-base md:text-lg text-gray-300 text-center">
                Your current Calories: {user_tdee} cal | Your target Calories Should Be Around: {user_goal_tdee} cal
              </p>
          )}
  
          <form onSubmit={handleFormSubmit} className="space-y-4 bg-slate-900 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-3/4 lg:w-1/2 xl:w-1/3 mb-8">
        <div className="flex flex-col space-y-2">
          <label htmlFor="diet" className="text-lg font-medium text-gray-100">Diet type:</label>
          <select id="diet" value={diet} onChange={(e) => setDiet(e.target.value)} className="py-2 px-4 border rounded" required>
            <option value="standard">Standard</option>
            <option value="vegetarian">Vegetarian</option>
            <option value="vegan">Vegan</option> {/* Added Vegan option */}
          </select>
        </div>
  
        <div className="flex flex-col space-y-2">
          <label htmlFor="foodType" className="text-lg font-medium text-gray-100">Preferred diet type:</label>
          <select id="foodType" value={foodType} onChange={(e) => setFoodType(e.target.value)} className="py-2 px-4 border rounded" required>
          <option value="American">American</option>
          <option value="Indian">Indian</option>
          <option value="Chinese">Chinese</option>
          <option value="African">African</option>
          <option value="European">European</option>
          <option value="Caribbean">Caribbean</option>
          <option value="Latin American">Latin American</option>
          <option value="Japanese">Japanese</option>
          <option value="Thai">Thai</option>
          <option value="Italian">Italian</option>
          <option value="Mexican">Mexican</option>
          <option value="Middle Eastern">Middle Eastern</option>
          <option value="Korean">Korean</option>
          <option value="Mediterranean">Mediterranean</option>
          <option value="French">French</option>
          <option value="Greek">Greek</option>
          <option value="Vietnamese">Vietnamese</option>
          <option value="Spanish">Spanish</option>
          <option value="Lebanese">Lebanese</option>
          <option value="Turkish">Turkish</option>
          <option value="Russian">Russian</option>
          <option value="Ethiopian">Ethiopian</option>
          </select>
        </div>
        <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                    Please select all protein sources you prefer in your diet:
                </h6>
                <div class="flex flex-col justify-center items-center h-full">
                    <span class="text-gray-400 text-sm">(Select as many as you want but atleast 2)</span>
                </div>

                <div className="flex flex-wrap justify-center mt-2">
                    {proteinSources.map(protein => (
                        <button
                            key={protein}
                            className={`m-1 px-3 py-1.5 rounded-md text-white ${selectedProtein.includes(protein) ? 'bg-blue-500' : 'bg-gray-900'} hover:bg-slate-700`}
                            onClick={(e)=> toggleProtein(protein, e)}>
                            {protein}
                        </button>
                    ))}
                </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="allergies" className="text-lg font-medium text-gray-100">Food you enjoy eating:</label>
            <div class="flex flex-col justify-center items-center h-full">
                    <span class="text-gray-400 text-sm">(Add as many as you want, will try to include them if it fits in your healthy meal plan)</span>
            </div>
            <textarea id="allergies" value={userFavFood} onChange={(e) => setUserFavFood(e.target.value)} placeholder="E.g. Chicken,potatoes,etc" className="py-2 px-4 border rounded w-full" rows="4"></textarea>
          </div>  
        <div className="flex flex-col space-y-2">
          <label htmlFor="allergies" className="text-lg font-medium text-gray-100">Food you are allergic to:</label>
          <textarea id="allergies" value={user_allergies} onChange={(e) => setUserAllergies(e.target.value)} placeholder="E.g. peanuts, shellfish, soy, lactose" className="py-2 px-4 border rounded" rows="4"></textarea>
        </div>
        
        <button type="submit" className="py-2 px-4 bg-slate-700 text-white rounded w-full hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">Submit</button>
      </form>
    </div>
  );  
};

export default MealPlanPreferences;
