import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate,useLocation } from 'react-router-dom';
import FitnessGoalsScreen from './components/CollectInfo/Screen1FitnessGoals';
import UserInfo from './components/CollectInfo/Screen2UserInfo';
import MealPlanPreferences from './components/CollectInfo/Screen3MealPrefrence';
import Dashboard from './components/Diet';
import WorkoutPlans from './components/WorkoutPlans';
import SupabaseSignup from './components/Auth/SupabaseSignup';
import SupabaseSignin from './components/Auth/SupabaseSignin';
import {getUserData} from './components/Auth/CompleteAuth'
import { Analytics } from '@vercel/analytics/react';


import GetFitterAILanding from './components/LandingPage';
import Header from './components/Navigation/Header';
import Sidebar from './components/Navigation/Sidebar';
import BottomNavbar from './components/Navigation/BottomNavbar';

import UpdateWorkoutInfo from './components/Settings/UpdateWorkoutInfo';
import UpdateDietInfo from './components/Settings/UpdateDietInfo';
import UpdateUserInfo from './components/Settings/UpdateUserInfo';
import UpdateOthers from './components/Settings/UpdateOthers';

import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import TermsOfService from './components/Pages/TermsOfService';
import AboutPage from './components/Pages/About';
import ContactPage from './components/Pages/Contact';
import BeginnerGuide from './components/Pages/BeginnerGuide'; 

import MyAlert from './components/Utils/Components/TopAlert'
import store from './store';  // Ensure you have the store exported from your store.js file
import { Provider } from 'react-redux';

import ChatWidget from './components/Chat/ChatWidget';
import FloatingButton from './components/Chat/FloatingButton';

import GoogleTagManager from './components/Ads/GoogleTag'

import { supabase } from './supabaseClient';

import ReactGA from 'react-ga';
import SavedDiets from './components/Saved/Public/SavedDiet';
import SavedWorkouts from './components/Saved/Public/SavedWorkout';

import SavedPlans from './components/Saved/List/SavedPlans'


const PrivateRoute = ({ authenticated, children }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!authenticated) {
      navigate('/signin');
    }
  }, [authenticated, navigate]);

  console.log('Authenticated in PrivateRoute:', authenticated); // Debug line

  if (!authenticated) return null;

  return children;
};

  // Custom Hook for tracking page views
  const usePageTracking = () => {
    let location = useLocation();

    // useEffect(() => {
    //   ReactGA.pageview(location.pathname + location.search);
    // }, [location]);
  };

  const PageTracker = () => {
    usePageTracking();
    return null; // This component doesn't render anything
  };


const App = () => {
  const [authenticated, setAuthenticated] = useState(false); // changed from null to false
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  // Add this line
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showPromoBanner, setShowPromoBanner] = useState(false); // new state for banner visibility
  const [userPro, setUserPro] = useState(localStorage.getItem('user_pro') || '0');


  const trackPurchaseDoneConversion = () => {
    // Add your ReactGA event tracking logic here
    ReactGA.event({
      category: 'SAAS',
      action: 'conversion',
      label: 'Purchase Done',
      value: 10.0,
      currency: 'USD',
      dimension1: 'AW-11454835762/VzVqCJ_igoMZELLYi9Yq'
    });
  };
  
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'user_pro') {
        setUserPro(event.newValue); // Update the 'userPro' state when 'user_pro' changes
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Check if user_pro is '1' and track the event
    if (userPro === '1') {
      trackPurchaseDoneConversion();
    }

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [userPro]);  // Add 'userPro' as a dependency

  
  


  useEffect(() => {
    const fetchData = async () => {
      const lastExecutionTimestamp = localStorage.getItem('lastExecutionTimestamp');
  
      if (!lastExecutionTimestamp || (Date.now() - Number(lastExecutionTimestamp) >= 86400000)) {
        console.log("It's been over a day since the last execution.")
        const { data: { session }, } = await supabase.auth.getSession();
        if (session) {
          const accessToken = session.access_token;
          const route = await getUserData(accessToken);
          if (route) {
            console.log("user data is updated");
          }
          else {
            console.log("user data is not updated");
          }
          localStorage.setItem('lastExecutionTimestamp', Date.now().toString());
        }
      }
      else {
        console.log("It's been less than a day since the last execution.")
      }
    };
  
    fetchData();
  }, []);
    


  const updateAuthState = async (event, session) => {
    setAuthenticated(!!session);
    setIsLoading(false);
  };

  useEffect(() => {
    // Function to handle resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  useEffect(() => {
    // Register the event listener
    const { data: authListener } = supabase.auth.onAuthStateChange(updateAuthState);
    
    // Check the initial authentication status
    (async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session != null) {
        console.log('data is true:', data);  // Debug line
        setAuthenticated(true);
      } else {
        console.log('data is false:', data);  // Debug line
        setAuthenticated(false);
      }
      setIsLoading(false);
    })();

    console.log('Authenticated in useEffect:', authenticated);
  }, [authenticated]);

  useEffect(() => {
    // ... existing code ...

    // Update banner visibility based on authentication
    setShowPromoBanner(!authenticated);
  }, [authenticated]);

  // Initialize ReactGA
  useEffect(() => {
    ReactGA.initialize('G-HHLNXGESQK');
  }, []);





  if (isLoading) {
    return <div>Loading...</div>;
  }


  const toggleChat = () => {
    console.log("Toggling chat!");
    setIsChatOpen(prevState => !prevState);
    console.log("isChatOpen after toggle:", isChatOpen);
  };

 
  
  const PromotionalBanner = () => {
    return (
      <div className="fixed top-0 left-0 w-full z-50 bg-gradient-to-r from-blue-500 to-purple-600 text-white text-center p-2 sm:p-4">
        <div className="overflow-hidden">
          <div className="sm:whitespace-nowrap animate-marquee">
            <span className="text-base sm:text-xl font-bold mx-2 sm:mx-4 block sm:inline">🌟 Black Friday Sale! Use code FLASH50 for 50% OFF 🌟</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Provider store={store}>
        <Router>
              <GoogleTagManager />
              <Analytics />
              <PageTracker /> 
              <div className="flex h-screen">
              <MyAlert/>
              {/* {showPromoBanner && <PromotionalBanner />} */}
              {authenticated === true && <Sidebar />}
              <div className={`flex flex-col flex-grow ${authenticated === true ? 'lg:pl-64' : ''} ${showPromoBanner ? 'pt-0' : ''}`}> 
                {/* Add padding top when promo banner is visible */}
                <Header />
                  <div className="flex-grow bg-gradient-to-r from-slate-900 to-slate-800 pb-14">  {/* Set background color and padding here */}
                    <Routes>
                      <Route path="/goal" element={
                      <PrivateRoute authenticated={authenticated}>
                      <FitnessGoalsScreen />
                      </PrivateRoute>
                      } />
                      <Route path="/" element={<GetFitterAILanding />} />
                      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                      <Route path="/termsofservice" element={<TermsOfService />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/contact" element={<ContactPage />} />
                      <Route path="/fulldiet/:id" element={<SavedDiets/>} />
                      <Route path="/fullworkout/:id" element={<SavedWorkouts/>} />
                      <Route path="/userinfo" element={
                        <PrivateRoute authenticated={authenticated}>
                          <UserInfo />
                        </PrivateRoute>
                      } />
                      <Route path="/beginnerguide" element={
                        <PrivateRoute authenticated={authenticated}>
                          <BeginnerGuide />
                        </PrivateRoute>
                      } />
                      <Route path="/mealplanpreferences" element={
                        <PrivateRoute authenticated={authenticated}>
                          <MealPlanPreferences />
                        </PrivateRoute>
                      } />
                      <Route path="/diet" element={
                        <PrivateRoute authenticated={authenticated}>
                          <Dashboard />
                        </PrivateRoute>
                      } />
                      <Route path="/workouts" element={
                        <PrivateRoute authenticated={authenticated}>
                          <WorkoutPlans />
                        </PrivateRoute>
                      } />
                      <Route path="/updatemyinfo" element={
                        <PrivateRoute authenticated={authenticated}>
                          <UpdateUserInfo />
                        </PrivateRoute>
                      } />
                      <Route path="/updatemydietinfo" element={
                        <PrivateRoute authenticated={authenticated}>
                          <UpdateDietInfo />
                        </PrivateRoute>
                      } />
                      <Route path="/updatemyworkoutinfo" element={
                        <PrivateRoute authenticated={authenticated}>
                          <UpdateWorkoutInfo />
                        </PrivateRoute>
                      } />
                      <Route path="/othersettings" element={
                        <PrivateRoute authenticated={authenticated}>
                          <UpdateOthers />
                        </PrivateRoute>
                      } />
                      <Route path="/saved" element={
                        <PrivateRoute authenticated={authenticated}>
                          <SavedPlans />
                        </PrivateRoute>
                      } />
                      <Route path="/signup" element={<SupabaseSignup />} />
                      <Route path="/signin" element={<SupabaseSignin />} />
                      </Routes>
                  </div>
                  {authenticated === true && isMobile && <BottomNavbar />} 
                    {authenticated && (
                      <>
                        <FloatingButton onClick={toggleChat} isChatOpen={isChatOpen} />
                        {isChatOpen && <ChatWidget />}
                      </>
                    )}
                </div>
              </div>
        </Router>
    </Provider>
  );
};

export default App;